import React, { useState } from "react";

import { IconContext } from "react-icons";
import { IoWalletOutline, IoMenu, IoClose } from "react-icons/io5";
import { ReactComponent as SolIcon } from "../assets/sol-white.svg";
import { ReactComponent as SavvioIcon } from "../assets/savvio-icon.svg";
import NavbarItems from "./navbarItems";
import {
  FaDiscord,
  FaTelegramPlane,
  FaTwitter,
  FaMediumM,
} from "react-icons/fa";

export default function Navbar() {
  const [menuVis, setMenuVis] = useState(false);
  var menuClass = menuVis
    ? "z-10 absolute h-screen ease-in-out duration-300 translate-x-0 opacity-100 w-full h-full bg-neutral-900  h-[100vh] p-10"
    : "z-10 fixed h-screen ease-in-out duration-300 -translate-x-full opacity-0 w-full h-full bg-neutral-900 h-[100vh] p-10";

  return (
    <>
      <header className="relative top-0 z-50  bg-blue-dark py-2 backdrop-blur-lg">
        {/* <div className="bar">
          <span className="bar_content">
            Whitelist open now. Only 15,000 spots available! Signup below before
            the time runs out! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;Whitelist open now. Only 15,000 spots available! Signup below
            before the time runs out! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;Whitelist open now. Only 15,000 spots available! Signup below
            before the time runs out! &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            &nbsp;Whitelist open now. Only 15,000 spots available! Signup below
            before the time runs out!
          </span>
        </div> */}
        <nav>
          {navbarElement(menuVis, setMenuVis)}
          {/* <div className={menuClass}>
            <IconContext.Provider
              value={{
                className: "ml-auto mr-5 h-24 text-white text-2xl ",
              }}
            >
              <div onClick={() => setMenuVis(false)}>
                <IoClose />
              </div>
            </IconContext.Provider>
            <div onClick={() => setMenuVis(false)}>
              <NavbarItems />
            </div>
          </div> */}
        </nav>
      </header>
    </>
  );
}

function navbarElement(menuVis, setMenuVis) {
  return (
    <div className="m-auto flex w-full max-w-screen-2xl items-center justify-between px-3 ">
      <SolIcon className="h-16" />

      <IconContext.Provider
        value={{
          className: "block lg:hidden mr-6 text-white text-4xl focus:none ",
        }}
      >
        {/* <div className="flex">
          <div onClick={() => setMenuVis(!menuVis)}>
            <IoMenu />
          </div>
        </div> */}{" "}
        <a
          href="/#"
          className="mx-6  items-center rounded bg-white  px-5 py-2 font-bold text-blue-dark lg:flex "
          target="_self"
          rel="noreferrer"
        >
          Coming Soon!
        </a>
      </IconContext.Provider>
      {/*<div className="hidden items-center lg:flex ">
        <NavbarItems /> */}
      {/* <div className="mr-5 flex gap-5">
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="/#" target="_self" rel="noreferrer">
              <FaMediumM />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="/#" target="_self" rel="noreferrer">
              <FaTelegramPlane />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="/#" target="_self" rel="noreferrer">
              <FaTwitter />
            </a>
          </IconContext.Provider>
          <IconContext.Provider value={{ className: "text-2xl" }}>
            <a href="/#" target="_self" rel="noreferrer">
              <FaDiscord />
            </a>
          </IconContext.Provider>
        </div> 
        <a
          href="/#"
          className="mx-6 hidden items-center rounded bg-white  px-5 py-2 font-bold text-blue-dark lg:flex "
          target="_self"
          rel="noreferrer"
        >
          Coming Soon!
        </a>
      </div> */}
    </div>
  );
}
