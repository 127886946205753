import "./App.css";
import "./input.css";
import React, { useState, useEffect } from "react";
import WhitelistForm from "./components/WhitelistForm";
import mockup from "./assets/mockup.png";
import { FaDiscord, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import token from "./assets/sensei-coin.png";
// partners
// import braindex from "./assets/partners/Briandex Logo.png";
// import falconx from "./assets/partners/FalconX Logo.png";
// import mexc from "./assets/partners/MEXC Logo.png";
// import sparrowswap from "./assets/partners/Sparrowswap.png";
// import wormhole from "./assets/partners/Wormhole.png";
// import yieldly from "./assets/partners/Yieldly logo.png";

import beanie from "./assets/team/beanie.jpg";
import seijas from "./assets/team/seijas.jpg";
import porky from "./assets/team/porky.jpg";
import jelly from "./assets/team/jelly.jpg";

import { IconContext } from "react-icons";

import { ReactComponent as SolSonicHero } from "./assets/hero.svg";

// const TARGET_TIME = "2023-06-06T14:00:00Z";

// const team = [
//   {
//     name: "Seijas san",
//     image: seijas,
//     info: "Data-driven technologist with over 5 years blockchain and start up experience. Passionate about innovation.",
//   },
//   {
//     name: "Beanie san",
//     image: beanie,
//     info: "Entrepreneur with a strong history of building & supporting the growth of some of the largest distributed ledger & DeFi companies.",
//   },
//   {
//     name: "Porky san",
//     image: porky,
//     info: "Blockchain developer with over 10 years design experience. Focussed on delivering cross-chain websites and webapps.",
//   },
//   {
//     name: "Jelly san",
//     image: jelly,
//     info: "Operations leader of international web3 startups. Over 5 years combined experience in finance and crypto industries.",
//   },
// ];

export default function Home() {
  const [showWhitelist, setShowWhitelist] = useState(true);
  // useEffect(() => {
  //   const currentTime = Math.floor(Date.now() / 1000); // Get current unix time
  //   const targetTime = Math.floor(new Date(TARGET_TIME).getTime() / 1000); // Set target unix time
  //   // console.log(currentTime + ";" + targetTime);
  //   if (currentTime >= targetTime) {
  //     setShowWhitelist(false);
  //   }
  // }, []);

  const [position, setPosition] = useState({ x: 0, y: 0 });

  // useEffect(() => {
  //   const handleMouseMove = (event) => {
  //     setPosition({ x: event.clientX, y: event.clientY });
  //   };

  //   document.addEventListener("mousemove", handleMouseMove);

  //   return () => {
  //     document.removeEventListener("mousemove", handleMouseMove);
  //   };
  // }, []);

  return (
    <>
      <section
        className="App relative h-auto w-full overflow-hidden 
  bg-blue-dark text-center"
      >
        <div className="translate-y-1/4">
          <div className="p-5 md:mt-20">
            <SolSonicHero className="mx-auto mb-5 h-48 max-w-full" />
            {/* <h1 className="text-5xl  md:text-[4rem] ">
              The Ultimate Destination for
              <br />
              <span className="font-bold">
                Gamified&nbsp;DeFi and Cross&nbsp;Chain&nbsp;Yield
              </span>
            </h1>
            {/* <h2 className="mt-5 text-4xl font-bold md:text-[4rem]">
              with the spiciest&nbsp;rewards.
            </h2> 
            <h3 className="mx-auto mt-10 mb-5 max-w-5xl text-xl font-light md:w-4/5 md:text-3xl">
              Savvio combines a no loss prize game, bonus rewards, and DeFi
              tools for maximum yield across SKALE, Vara, Sei and more.
            </h3>
            */}
          </div>

          <div className="mx-7 ">
            <div className="gradient border-5 relative mx-auto my-2 max-w-sm items-center rounded border-transparent px-5 py-2 text-2xl text-blue-dark transition-all hover:cursor-pointer hover:border-white hover:bg-yellow/50  hover:text-blue-dark md:mx-auto md:w-80">
              <a
                href="/#"
                className=" flex items-center justify-around hover:text-white"
                target="_self"
              >
                Login
              </a>
              <i />
            </div>
          </div>
        </div>
      </section>

      {/* <section
        className=" 
  mx-auto flex w-full max-w-6xl flex-col text-left md:flex-row md:text-center"
      >
        <div className="m-10 max-w-md md:my-16 md:mx-10">
          <h2 className="text-left text-6xl font-medium text-blue-dark">
            The <span className="text-yellow">Road</span> Ahead
          </h2>
        </div>
        <div className="m-10 md:w-full">
          <Timeline />
        </div>
      </section> */}
      {/* <div className="relative">
        <div
          className="from-40% absolute bottom-0 z-10 h-32 w-full
       bg-gradient-to-t from-[#ffffff] md:h-48"
        />
      </div> */}
      {/* <section
        className=" 
  mx-auto my-20 flex w-full max-w-6xl flex-col text-left md:flex-row md:text-center"
      >
        <div className="m-10 max-w-md md:my-16 md:mx-10">
          <h2 className="text-left text-6xl font-medium text-blue-dark">
            Meet the <span className="text-yellow">Team</span>
          </h2>
          <p className="my-5 text-left text-blue-dark">
            Savvio is built and backed by industry experts with multiple years
            of collective Web3 experience.
          </p>
        </div>
        <div className="m-0 grid gap-10 sm:m-10 sm:grid-cols-2 md:w-full">
          {team.map((human, i) => (
            <div
              key={i}
              className="relative mx-auto h-80 w-64 rounded-lg border border-blue-dark bg-white drop-shadow-2xl hover:drop-shadow"
            >
              <div className="absolute">
                <img
                  className=" inset-0 z-0 rounded-lg bg-contain bg-center"
                  src={human.image}
                />
                <h4 className="z-10 my-2 w-full text-center text-2xl font-bold text-blue-dark drop-shadow-md">
                  {human.name}
                </h4>
              </div>
              <div className="absolute inset-0 z-10 flex flex-col items-center justify-center rounded-lg bg-white/70 p-4 text-blue-dark opacity-0 backdrop-blur-lg duration-300 hover:opacity-100">
                <h4 className="z-10 my-2 w-full text-center text-2xl font-bold text-blue-dark drop-shadow-md">
                  {human.name}
                </h4>
                {human.info}
              </div>
            </div>
          ))}
        </div>
      </section> */}
      <footer className="w-full ">
        {/* <img src={mockup} className="h-auto w-full" /> */}
        <div className="gradient py-5 text-center font-light text-blue-dark">
          <div className="mx-5 flex flex-col justify-between md:flex-row">
            <p>Copyright &copy; SolSonic, 2023.</p>
            <p>Coming Soon!</p>
            {/* <div className="mt-2 flex flex-col md:mt-0 md:flex-row  md:gap-5">
              <a
                href="https://branding.savvio.io/savvio%20logos.zip"
                style={{ textDecoration: "underline" }}
              >
                Download Branding
              </a>
              <a target="_blank" href="https://app.savvio.io/tnc">
                Terms & Condition
              </a>
            </div> */}
          </div>
        </div>
      </footer>
    </>
  );
}
